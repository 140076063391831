@import "../../styles/breakpoints";

.Carousel {
  @include smallMax {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.CarouselViewport {
  overflow: hidden;
  border-radius: 0.5rem;

  @include medium {
    border: 1px solid #d8dada;

    &.unframed {
      border: 0;
    }
  }

}

.CarouselTrack {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  touch-action: pan-x;

  > * {
    height: auto;
    pointer-events: none;

    @include smallMax {
      margin: 0 10px;
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

.CarouselBars {
  display: flex;
  justify-content: center;

  ol {
    margin-bottom: 0;
  }

  li {
    height: 20px;
  }
}

.CarouselNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CarouselNavButtons {
  position: relative;
  left: 8px;

  button {
    width: 34px;
    height: 34px;
    border: 0;
    background: none;
    padding: 8px;
    margin: 0;
    cursor: pointer;

    > svg {
      width: 18px;
      height: 18px;
      display: block;
    }

    &:hover path {
      stroke: black;
    }
  }
}