@import "../../styles/breakpoints";

.BodyText {
  span {
    font-size: inherit;
    line-height: inherit;
    color: currentColor;

    a {
      color: currentColor;
      text-decoration: underline;
    }

    p {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }

    ul {
      padding-left: 20px;

      li:not(:last-child) {
        margin-bottom: 0.25em;
      }
    }
  }

  &.textSizeSmall {
    font-size: 14px;
    line-height: 17px;
  }

  &.textSizeMedium {
    font-size: 15.5px;
    line-height: 20px;
  }

  &.textSizeLarge {
    font-size: 16px;
    line-height: 20px;

    @include large {
      font-size: 18px;
      line-height: 22px;
    }

    @include xlarge {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &.textSizeExtraLarge {
    font-size: 21px;
    line-height: 24px;

    @include large {
      font-size: 21.5px;
      line-height: 24px;
    }

    @include xlarge {
      font-size: 22px;
      line-height: 24px;
    }
  }

  &.textSizeExtraExtraLarge {
    font-size: 22px;
    line-height: 24px;

    @include large {
      font-size: 22.5px;
      line-height: 24px;
    }

    @include xlarge {
      font-size: 23px;
      line-height: 24px;
    }
  }
}
